/**
 *
 * ChangePasswordFormik
 *
 *
 */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import Spinner from 'components/Spinner';
import Button from 'components/Elements/Button';
import { FormWrapper, StyledField } from 'components/lib/Formik';

import validate from './validators';

const ChangePasswordFormik = ({ initialValues, onSubmit }) => {
  const handleChange = useCallback((field, setFieldValue) => evt => {
    const { value } = evt.target;
    setFieldValue(field, value);
  });
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        values,
        touched,
        errors,
        setFieldValue,
        setFieldTouched,
        // eslint-disable-next-line
        ...rest
      }) => {
        return (
          <Form style={{ position: 'relative' }}>
            <FormWrapper>
              {/* <Spinner loading={isSubmitting} cover /> */}
              <FormFields>
                <StyledField
                  useAnimated
                  type="password"
                  name="currentPassword"
                  placeholder="Current Password"
                  touched={touched}
                  errors={errors}
                  value={values.currentPassword}
                  onBlur={() => setFieldTouched('currentPassword', true)}
                  onChange={handleChange('currentPassword', setFieldValue)}
                />
                <StyledField
                  useAnimated
                  type="password"
                  name="newPassword"
                  placeholder="New Password"
                  touched={touched}
                  errors={errors}
                  value={values.newPassword}
                  onBlur={() => setFieldTouched('newPassword', true)}
                  onChange={handleChange('newPassword', setFieldValue)}
                />
                <StyledField
                  useAnimated
                  type="password"
                  name="newRePassword"
                  placeholder="Re-Enter New Password"
                  touched={touched}
                  errors={errors}
                  value={values.newRePassword}
                  onBlur={() => setFieldTouched('newRePassword', true)}
                  onChange={handleChange('newRePassword', setFieldValue)}
                />
              </FormFields>

              <Controls>
                <Button light type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              </Controls>
            </FormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

ChangePasswordFormik.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default ChangePasswordFormik;

const FormFields = styled.div`
  > div {
    margin-bottom: 30px;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
  padding-bottom: 25px;

  ${({ theme }) => theme.media.max.mobile`
    button {
      padding: 15px;
      &:first-of-type {
        margin-right: 5px;
      }
    }
`}
`;
