/**
 *
 * Validators for Change Password Formik
 *
 *
 */

export default values => {
  const errors = {};

  switch (true) {
    case !values.currentPassword:
      errors.currentPassword = 'Required';
      break;
    case !values.newPassword:
      errors.newPassword = 'Required';
      break;
    case values.newPassword === values.currentPassword:
      errors.newPassword = 'New password must be different';
      break;
    case !values.newRePassword:
      errors.newRePassword = 'Required';
      break;
    case values.newRePassword !== values.newPassword:
      errors.newRePassword = 'New passwords must match';
      break;
    default:
  }

  return errors;
};
