/**
 *
 * ChangePassword
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';

import Link from 'components/Elements/Link';
import { ACCOUNT_ROUTE, CHANGE_EMAIL_ROUTE } from 'utils/routes';
import { updateMe } from 'utils/api/users';
import { toastSuccess, toastError } from 'utils/toastify';
import ChangePasswordFormik from './ChangePasswordFormik';

const ChangePassword = props => {
  return (
    <Container>
      <StyledLink to="/account" primary>
        Back to Account
      </StyledLink>
      <Title>Change Password</Title>
      <ChangePasswordFormik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          newRePassword: '',
        }}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          try {
            const data = {
              password: values.currentPassword,
              newPassword: values.newPassword,
            };
            const res = await updateMe(data);
            if (res) {
              setTimeout(() => {
                toastSuccess('Successfully changed password!');
                setSubmitting(false);
                navigate('/account');
              }, 400);
            } else {
              toastError('Sorry, something went wrong.');
            }
          } catch (err) {
            // toastError(err.message || 'Sorry, something went wrong.');
            if (err.code && (err.code === 422 || err.code === 401)) {
              if (err.location === 'password') {
                setFieldError('currentPassword', err.message);
              } else {
                setFieldError(err.location, err.message);
              }
            }
            setSubmitting(false);
          }
        }}
      />
    </Container>
  );
};

ChangePassword.propTypes = {};

export default ChangePassword;

const Container = styled.div`
  ${({ theme }) => theme.styles.grow}
  color: ${({ theme }) => theme.colors.Primary};
  max-width: 500px;
  margin: 100px auto 0;
  padding: 25px;
  border-radius: 3px;
`;

const Title = styled.h2`
  margin-left: 15px;
`;

const StyledLink = styled(Link)`
  font-size: 1.6em;
  margin: 0px 15px;
  margin-bottom: 30px;
  position: relative;
  &:before {
    content: '';
    height: 10px;
    width: 10px;
    border-left: 3px solid ${({ theme }) => theme.colors.Primary};
    border-top: 3px solid ${({ theme }) => theme.colors.Primary};
    position: absolute;
    left: -20px;
    top: 2px;
    transform: rotate(-45deg);
  }
`;
